.destacada-container {
	color: var(--dark-black);
	letter-spacing: 0;
	text-align: left;
	width: 100%;

}

.img { 
	width: 100%;
	height: auto;
	object-fit: cover;
}

.destacada-container-text {
	margin-bottom: 0rem;
	width: 100%;
	display: grid;

}

.destacada-title {
	font-size: 2.15rem;
	margin-top: 0rem;
	margin-bottom: 0.8rem;
	font-weight: 500;
}

.destacada-summary {
	font-size: 1rem;
	line-height: 1.375;
	color: var(--gray-text);
	font-weight: 400;
}

.background{
	background: #fff000;
	padding: 20px 10px;
}

.destacada-autor {
	margin:0.5rem 0 1rem 0;
	color: var(--medium-black);
    font-weight: 700;
    font-size: .9rem;
}

.display-none {
	display: none;
}

@media (max-width: 768px) {
.img-container {
	order: 1;
	margin-top: 0.5rem;
}
.destacada-title {
	order: 2;
	font-size: 1.5rem;
	letter-spacing: -1px;
	margin-top: 0.3rem;
	margin-bottom: 0.8rem;
	line-height: 1.2;
}
.destacada-summary {
	order: 3;
	margin: 0rem 0;
}
.destacada-autor {
	order: 4;
	margin-top: 0.6rem;
}
}